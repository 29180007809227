
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns7fd6c7479f62e5c1ede5c1fd46ece574en from './en.yaml'
            i18n.addResourceBundle('en', '7fd6c7479f62e5c1ede5c1fd46ece574', flattenObject(ns7fd6c7479f62e5c1ede5c1fd46ece574en), true, true);
        
            import ns7fd6c7479f62e5c1ede5c1fd46ece574fr from './fr.yaml'
            i18n.addResourceBundle('fr', '7fd6c7479f62e5c1ede5c1fd46ece574', flattenObject(ns7fd6c7479f62e5c1ede5c1fd46ece574fr), true, true);
        
        export default '7fd6c7479f62e5c1ede5c1fd46ece574';
    