// @flow
import * as React from 'react'
import cn from 'classnames'

import withTheme from 'hoc/withTheme'
import supportedThemes, { Theme } from './themes/__supportedThemes'

type FormProps = {
    onClick?: (e: SyntheticEvent) => void,
    onSubmit?: (e: SyntheticEvent) => void,
    children?: ?React.Node,
    theme: Theme
};

export default withTheme(supportedThemes)(function Form (props: FormProps) {
    return (
        <form
            onClick={props.onClick}
            onSubmit={props.onSubmit}
            style={{
                '--color-selected': props.theme.colorSelected,
                '--color-title-color': props.theme.titleColor
            }}
        >
            {props.children}
        </form>
    )
})

export function Row ({ children }: { children: React.Children }) {
    return <div>{children}</div>
}

export function RowColumns ({ children, className }: { children: React.Children }) {
    return <div className={cn(className)}>{children}</div>
}

export function RowColumn (props: { children: React.Children, size?: number }) {
    return <div style={{ flexBasis: props.size * 100 + '%' }}>{props.children}</div>
}

type SubmitProps = {
    isLoading?: boolean,
    isDisabled?: boolean,
    label?: string,
    color?: string,
    size?: string
};

export function Divider () {
    return <div/>
}

export const Submit = withTheme(supportedThemes)(function (props: SubmitProps) {
    return (
        <button
            onClick={() => {
            }}
            style={{
                background: '#646464',
                border: '1px solid #cbcbcb',
                color: '#fff',
                padding: '.25em .5em',
                float: 'right'
            }}
            disabled={props.isDisabled}
        >{props.label}</button>
    )
})

Submit.defaultProps = {
    isLoading: false,
    isDisabled: false,
    color: 'primary',
    size: 'medium',
    cancel: 'Cancel',
    submit: 'Submit'
}

export { default as Field } from './Fields/Field'
export { default as Label } from './Label'
export { default as Panel } from './Panel'
export { default as Tabs } from './Tabs'
export { default as RadioButtons } from './RadioButtons'
export { default as Choices } from './Fields/Choices'
export { default as Dropdown } from './Fields/Dropdown'
export { default as ModelPickerDropdown } from './Fields/ModelPickerDropdown'
export { default as Checkbox } from './Fields/Checkbox'
