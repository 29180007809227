// @flow
import merge from 'deepmerge'
import URL from 'url-parse'

import { sleep } from './helpers'

import { Client, Credentials, GetCampaignResponse } from './Client'
import { CampaignNotFoundError } from './errors'
import type { Lead } from './Entities'
import VEHICLES from './mock_car_models'

type Type = 'automotive' | 'higher-education';

type Options = {
    campaign?: { [string]: any },
    lead?: { [string]: any }
}

export default class MockClient implements Client {
    type: Type
    options: Options

    constructor (type: Type, options?: Options) {
        this.type = type
        this.options = options
    }

    async getCampaign (url: URL, credentials?: Credentials, token?: string, locale?: string): GetCampaignResponse {
        if (!credentials.username) {
            return Promise.reject(new CampaignNotFoundError('Campaign not found'))
        }

        if (credentials.password === 'bad') {
            return Promise.reject(new CampaignNotFoundError('Campaign not found'))
        }

        let mockCampaign
        try {
            mockCampaign = (await import(`./mock-campaign-${this.type}.js`)).makeCampaign(credentials.username, this.options.campaign)
        } catch (error) {
            console.warn(error)
            mockCampaign = (await import(`./mock-campaign.js`)).default
        }

        await sleep(250)
        return Promise.resolve({ campaign: mockCampaign, token: `mock-token.${credentials.username}.${credentials.password}` })
    }

    async getLead (token: string, code: ?string): Lead {
        let mockLead

        if (code.toLowerCase() === 'bad') {
            throw new Error(`Code "${code}" does not match the url campaign.`)
        }

        let mockLeadJson = localStorage.getItem('MockLead.' + token)
        if (mockLeadJson) {
            return Promise.resolve(JSON.parse(mockLeadJson))
        }

        try {
            mockLead = (await import(`./mock-lead-${this.type}.js`)).default

            // if (typeof mockLead === 'function') {
            //     mockLead = mockLead(await this.getCampaign(url))
            // }
        } catch (error) {
            console.warn(`Cannot find mock lead for '${this.type}', using default`)
            mockLead = (await import(`./mock-lead.js`)).default
        }

        await sleep(250)
        return Promise.resolve(merge(mockLead, this.options.lead))
    }

    async updateLead (token: string, code: ?string, lead: Lead): Lead {
        localStorage.setItem('MockLead.' + token, JSON.stringify(lead))
        await sleep(2000)
        return lead
    }

    async patchLead (token: string, code: ?string, fields: { [string]: any }, isComplete: boolean): Lead {
        let lead = merge(await this.getLead(token, code), { fields, isComplete })

        // if (typeof fields['newVehicleModel'] !== 'undefined') {
        //     const campaign = await this.getCampaign(url)
        //
        //     lead = merge(lead, {
        //         incentives: {
        //             voucher: {
        //                 value: campaign.models.find(model => model.name === fields['newVehicleModel']).voucherValue,
        //                 name: campaign.incentives.voucher.name,
        //                 terms: campaign.incentives.voucher.terms
        //             }
        //         }
        //     }, { arrayMerge: overwriteMerge })
        // }

        localStorage.setItem('MockLead.' + token, JSON.stringify(lead))
        await sleep(2000)
        return lead
    }

    async sendAutoresponder (token: string, attachments: {[string]: Blob}): void {
        console.log('Mocking sending autoresponder...')
        console.log('with attachments', attachments)
        console.log('Autoresponder sent...')
    }

    async fetchMakesAndModels (token: string): void {
        return VEHICLES
    }
}
