// @flow
import React from 'react'
import MaskedInput from 'react-text-mask'

import percentageMask from '../Helpers/InputMask/percentageMask'
import phoneMask from '../Helpers/InputMask/phoneMask'
import makeCurrencyMask from '../Helpers/InputMask/currencyMask'
import { makeId } from '../Helpers'
import Label from '../Label'

import { ParseNumber } from 'i18n/NumberFormatter'

type Props = {
    required?: boolean,
    disabled?: boolean,
    type?: string,
    name?: string,
    defaultValue?: ?string,
    value?: ?string,
    label?: string,
    placeholder?: string,
    noBorder?: boolean,
    onChange?: (e: SyntheticEvent) => void,
    size?: 'medium',
    min?: number,
    max?: number,
    step?: number,
    locale?: string,
    currency?: string,
};

export default function Field (props: Props) {
    const id = React.useMemo(() => makeId('form-field'), [])

    const handleChange = ({ currentTarget }: SyntheticInputEvent<HTMLInputElement>) => {
        if (typeof props.onChange === 'function') { props.onChange(currentTarget.value) }
    }
    let attr = {}

    if (props.type === 'range') {
        attr['min'] = props.min || 0
        attr['max'] = props.max || 100
        attr['step'] = props.step
    }

    const commonProps = {
        id: id,
        type: 'text',
        required: props.required,
        disabled: props.disabled,
        onChange: handleChange,
        placeholder: props.placeholder,
        ...attr
    }

    if (props.name) {
        commonProps.name = props.name
    }

    if (props.type === 'number') {
        commonProps.type = 'number'
        commonProps.onChange = ({ currentTarget }) => typeof props.onChange === 'function' && props.onChange(ParseNumber(props.locale, currentTarget.value))
    }

    if (typeof props.value !== 'undefined') {
        commonProps.value = props.value
    } else if (typeof props.defaultValue !== 'undefined') {
        commonProps.defaultValue = props.defaultValue
    }

    const buildType = () => {
        if (props.type) {
            if (props.type === 'email') {
                commonProps.type = 'email'
                commonProps.title = 'Email address: name@domain.tld'
                commonProps.inputMode = 'email'
                commonProps.pattern = '^(([^<>()\\[\\]\\.,;:\\s@]+(\\.[^<>()\\[\\]\\.,;:\\s@]+)*))@([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}$'
            }
            if (props.type === 'phone') {
                commonProps.type = 'text'
                commonProps.inputMode = 'tel'
                return <MaskedInput
                    mask={phoneMask}
                    pattern={'\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}'}
                    title="Phone number: (###) ###-####"
                    guide={false}
                    {...commonProps}
                />
            }
            if (props.type === 'currency') {
                commonProps.type = 'text'
                return <MaskedInput
                    mask={makeCurrencyMask(props.currency, props.locale)}
                    guide={false}
                    {...commonProps}
                    onChange={({ currentTarget }) => (
                        typeof props.onChange === 'function' && props.onChange(ParseNumber(props.locale, currentTarget.value))
                    )}
                />
            }
            if (props.type === 'percent') {
                commonProps.type = 'text'
                return <MaskedInput
                    mask={percentageMask}
                    guide={false}
                    {...commonProps}
                />
            }
            return <input {...commonProps} />
        }
        return props.children
    }

    return (
        <>
            {props.label ? (
                <Label for={id}>{props.label}</Label>
            ) : null}

            {buildType()}
        </>
    )
}

Field.defaultProps = {
    required: true,
    noBorder: false
}
