// @flow
import * as React from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import queryString from 'query-string'

import { useTranslation } from 'react-i18next'
import transDomain from './translations/index.translations'

import { Store } from 'Store'

import styles from './styles.module.scss'

import { fetchCampaignAndLead } from 'Actions'
import { CampaignNotFoundError } from 'api/errors'

type Props = {
    location: Location
}

export default withRouter((props: Props) => {
    const [error, setError] = React.useState(null)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [isSubmitted, setIsSubmitted] = React.useState(false)
    const { dispatch } = React.useContext(Store)
    const { t } = useTranslation(transDomain)

    let hashQuery = queryString.parse(window.location.hash)
    const defaultUserName = hashQuery['user'] || ''
    const defaultPassword = hashQuery['password'] || ''

    if (isSubmitted) {
        let to = `/new_appraisal_form${window.location.search}`

        if (defaultPassword) {
            to += '#sId'
        }

        return <Redirect exact from="/" to={to} push/>
    }

    const submitForm = async e => {
        e.preventDefault()
        setIsSubmitting(true)
        const username = e.target.elements.username.value
        const password = e.target.elements.password.value

        try {
            await fetchCampaignAndLead(dispatch, username, password)
        } catch (e) {
            if (e instanceof CampaignNotFoundError) {
                setError('Username or password are not valid')
                return
            }
        } finally {
            setIsSubmitting(false)
        }

        setIsSubmitted(true)
    }

    return (
        <div className={styles.login}>
            <div className={styles.header}>
                <div className={styles.inner}>
                    <h1>{t('title')}</h1>
                </div>
            </div>

            <div className={styles.content}>
                <form onSubmit={submitForm} className={styles.form}>
                    <h2>{t('form_title')}</h2>
                    <p>{t('form_subtitle')}</p>


                    <div className={styles.formRow}>
                        <div className={styles.full}>
                            <label htmlFor="username">{t('label.username')}</label>
                            <input type="text" name="username" id="username" defaultValue={defaultUserName}/>
                        </div>
                    </div>

                    <div className={styles.formRow}>
                        <div className={styles.full}>
                            <label htmlFor="password">{t('label.password')}</label>
                            <input type="password" name="password" id="password" defaultValue={defaultPassword}/>
                        </div>
                    </div>

                    <div className={styles.formRow}>
                        <div className={styles.full}>
                            {error && (
                                <span className={styles.error}>{error}</span>
                            )}
                            <input type="submit" name="submit" value={isSubmitting ? t('label.submitLoading') : t('label.submit')} disabled={isSubmitting}/>
                        </div>
                    </div>
                </form>
            </div>

            <div className={styles.footer}>
                <h1>{t('footer.title')}</h1>

                <div className={styles.copyright}>{t('footer.copyright')} {new Date().getFullYear()}</div>
            </div>
        </div>
    )
})
