// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'
import styles from './styles.module.scss'
import cn from 'classnames'

import { Store } from 'Store'

type Props = WithFormProps | {
    values: {
        firstName: ?string,
        lastName: ?string,
        email: ?string,
        mobilePhone: ?string,
        currentVehicleYear?: ?string,
        currentVehicleMake?: ?string,
        currentVehicleModel?: ?string,
        currentVehicleColor?: ?string,
        currentVehicleMileage?: ?string,
    }
}

function DotdForm (props: Props) {
    const { state } = React.useContext(Store)
    const { t } = useTranslation(transDomain)

    const makes = React.useMemo(() => (
        state.campaign.models.map(model => model.make).filter((value, index, self) => self.indexOf(value) === index)
    ), [state.campaign.models])

    const models = React.useMemo(() => (
        state.campaign.models.filter(model => model.make === props.values.newVehicleMake).reduce((models, model) => ({
            ...models,
            [model.year + '::' + model.name]: ((model.year || '') + ' ' + model.name).trim(),
        }), {})
    ), [props.values.newVehicleMake, state.campaign.models])

    const defaultMileageOption = React.useMemo(() => {
        return props.values.currentVehicleMileage
            ? DEFAULT_MILEAGE_OPTIONS.reduce(function (prev, curr) {
                return (Math.abs(curr - props.values.currentVehicleMileage) < Math.abs(prev - props.values.currentVehicleMileage) ? curr : prev)
            })
            : ''
    }, [props.values.currentVehicleMileage])

    return (
        <div className={styles.form} id="client-info">
            <div className={styles.formRow}>
                <div className={styles.half}>
                    <Form.Field
                        // error={props.errors.firstName || null}
                        defaultValue={props.values.firstName || null}
                        onChange={value => props.handleFieldChange('firstName', value)}
                        type='text'
                        label={t('label.firstName')}
                    />
                </div>

                <div className={styles.half}>
                    <Form.Field
                        // error={props.errors.lastName || null}
                        defaultValue={props.values.lastName || null}
                        onChange={value => props.handleFieldChange('lastName', value)}
                        type='text'
                        label={t('label.lastName')}
                    />
                </div>
            </div>

            <div className={styles.formRow}>
                <div className={styles.phone}>
                    <Form.Field
                        // error={props.errors.mobilePhone || null}
                        defaultValue={props.values.mobilePhone || null}
                        onChange={value =>
                            props.handleFieldChange('mobilePhone', value)
                        }
                        type='phone'
                        label={t('label.mobilePhone')}
                        required={true}
                    />
                </div>

                <div className={styles.email}>
                    <Form.Field
                        // error={props.errors.email || null}
                        defaultValue={props.values.email || null}
                        onChange={value =>
                            props.handleFieldChange('email', value)
                        }
                        type='email'
                        label={t('label.emailAddress')}
                    />
                </div>
            </div>

            <div className={styles.formRow}>
                <div className={styles.third}>
                    <Form.Field label={t('label.vehicleYear.label')}>
                        <Form.Field
                            type='text'
                            // error={props.errors.currentVehicleYear || null}
                            onChange={value => props.handleFieldChange('currentVehicleYear', parseInt(value, 10))}
                            defaultValue={props.values.currentVehicleYear}
                        />
                    </Form.Field>
                </div>

                <div className={styles.third}>
                    <Form.Field label={t('label.vehicleMake.label')}>
                        <Form.Field
                            type='text'
                            // error={props.errors.currentVehicleMake || null}
                            onChange={value => props.handleFieldChange('currentVehicleMake', value)}
                            defaultValue={props.values.currentVehicleMake}
                        />
                    </Form.Field>
                </div>

                <div className={styles.third}>
                    <Form.Field label={t('label.vehicleModel.label')}>
                        <Form.Field
                            type='text'
                            // error={props.errors.currentVehicleMake || null}
                            onChange={value => props.handleFieldChange('currentVehicleModel', value)}
                            defaultValue={props.values.currentVehicleModel}
                        />
                    </Form.Field>
                </div>
            </div>

            <div className={styles.formRow}>
                <div className={styles.half}>
                    <Form.Field label={t('label.vehicleColor.label')}>
                        <Form.Dropdown
                            // error={props.errors.currentVehicleMake || null}
                            label={t('label.vehicleColor.label')}
                            placeholder={t('label.vehicleColor.placeholder')}
                            options={[
                                'White',
                                'Black',
                                'Silver',
                                'Grey',
                                'Red',
                                'Blue',
                                'Brown/Beige',
                                'Green',
                                'Yellow/Gold',
                                'Other'
                            ]}
                            onChange={value => props.handleFieldChange('currentVehicleColor', value)}
                            defaultValue={props.values.currentVehicleColor}
                        />
                    </Form.Field>
                </div>

                <div className={styles.half}>
                    <Form.Field label={t('label.vehicleMileage.label')}>
                        <Form.Dropdown
                            placeholder={t('label.vehicleMileage.placeholder')}
                            options={DEFAULT_MILEAGE_OPTIONS}
                            optionFormat={'label.vehicleMileage.options.%name%'}
                            trans={t}
                            onChange={value => props.handleFieldChange('currentVehicleMileage', value ? parseInt(value, 10) : '')}
                            defaultValue={defaultMileageOption.toString()}
                        />
                    </Form.Field>
                </div>
            </div>

            <div className={styles.formRow}>
                <div className={styles.half}>
                    <Form.Field label={t('label.replacementVehicle')}>
                        <Form.Dropdown
                            // error={props.errors.newVehicleMake || null}
                            placeholder={t('choose')}
                            options={makes}
                            onChange={value => props.handleFieldChange('newVehicleMake', value)}
                            defaultValue={props.values.newVehicleMake}
                        />
                    </Form.Field>
                </div>

                <div className={styles.half}>
                    <Form.Field label={t('label.replacementVehicleModelYear')}>
                        <Form.Dropdown
                            // error={props.errors.newVehicleModel || null}
                            placeholder={t('choose')}
                            options={models}
                            onChange={value => {
                                const [year, name] = value.split('::')
                                props.handleFieldChange('newVehicleModel', name)
                                props.handleFieldChange('newVehicleModelYear', year !== 'null' ? parseInt(year, 10) : null)
                            }}
                            defaultValue={props.values.newVehicleModelYear + '::' + props.values.newVehicleModel}
                        />
                    </Form.Field>
                </div>
            </div>

            <div className={cn(styles.formRow, styles.last)}>
                <div className={styles.full}>
                    <Form.Submit
                        isDisabled={props.isSubmitted || props.isLoading}
                        label={props.isLoading ? t('label.saving') : (props.isSubmitted ? t('label.submitted') : t('label.submit'))}
                    />
                </div>
            </div>
        </div>
    )
}

export default withForm()(DotdForm)

const DEFAULT_MILEAGE_OPTIONS = [5000, 15000, 30000, 50000, 70000, 90000, 100000]
