// @flow
import React, { Component } from 'react'

type Props = {
    required?: boolean,
    disabled?: boolean,
    placeholder: ?string,
    options: Array<string> | { [value: string]: string },
    value?: string,
    defaultValue?: string,
    onChange: Function,
    trans?: string => string,
    optionFormat?: string
};

export default class Dropdown extends Component<Props> {
    static defaultProps = {
        required: true,
        disabled: false,
        placeholder: 'Select',
        optionFormat: '%name%'
    }

    constructor (props) {
        super(props)

        this.select = React.createRef()
    }

    get options (): { [string]: string } {
        if (!Array.isArray(this.props.options)) {
            return this.props.options
        }

        let options = {}

        this.props.options.forEach(item => {
            if (this.props.optionFormat) {
                options[item] = this.formatOptionLabel(item)
            } else {
                options[item] = item
            }
        })

        return options
    }

    formatOptionLabel (label: string): string {
        label = this.props.optionFormat.replace('%name%', `${label}`)

        if (!this.props.trans) {
            return label
        }

        return this.props.trans(label)
    }

    render () {
        let {
            placeholder,
            value,
            defaultValue,
            onChange
        }: Props = this.props
        let options = this.options

        let valueProp
        if (value) {
            valueProp = { value: value.toString() }
        } else {
            if (defaultValue) {
                valueProp = { defaultValue: defaultValue.toString() }
            } else {
                valueProp = { value: '' }
            }
        }

        const selectOptions = [
            placeholder ? { value: '', label: placeholder } : {},
            ...Object.keys(options).map(key => ({ value: key, label: options[key] }))
        ]

        return (
            <select
                {...valueProp}
                className="sh-select__container"
                ref={this.select}
                placeholder={placeholder || null}
                required={this.props.required}
                onFocus={this.props.onFocus || null}
                onBlur={this.props.onBlur || null}
                onChange={selectedOption => onChange(selectedOption.target.value)}
            >
                {selectOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
            </select>
        )
    }
}
