// @flow
import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Login from 'pages/Login'
import LeadGenForm from 'pages/LeadGenForm'
import ThemeWrapper from 'components/ThemeWrapper'
import LanguageSwitcher from 'components/LanguageSwitcher'

const AppRoute = ({ component: Component, layout: Layout = MainLayout, ...rest }) => (
    <Route {...rest} render={props => (
        <Layout>
            <Component {...props} />
        </Layout>
    )}/>
)

const MainLayout = props => {
    return (
        <div>
            <LanguageSwitcher/>
            {props.children}
        </div>
    )
}

export default function App () {
    return (
        <Suspense fallback={<div>loading...</div>}>
            <ThemeWrapper>
                <BrowserRouter>
                    <Switch>
                        <AppRoute exact path='/' component={Login}/>
                        <AppRoute exact path='/new_appraisal_form' component={LeadGenForm}/>
                    </Switch>
                </BrowserRouter>
            </ThemeWrapper>
        </Suspense>
    )
}
