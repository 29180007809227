// @flow
import * as React from 'react'

type Props = {
    children: React.Children,
    for?: string,
    isFloating: boolean
}

export default function Label (props: Props) {
    return (
        <label htmlFor={props.for}>{props.children}</label>
    )
}

Label.defaultProps = {
    isFloating: false
}
