// @flow
import URL from 'url-parse'
import QueryString from 'query-string'

import { cleanupObject } from './helpers'
import { CampaignNotFoundError } from './errors'
import { Client, Credentials } from './Client'
import type { Campaign, Lead } from './Entities'

export default class FetchClient implements Client {
    _baseUri: string

    constructor (baseUri: string) {
        this._baseUri = baseUri
    }

    getCampaign (url: URL, credentials?: Credentials, token?: string, locale?: string): Promise<Campaign> {
        const queryString = QueryString.stringify({
            url: url.toString(),
            ...credentials
        })

        let headers = {}

        if (token) {
            headers['Token'] = token
        }

        return fetch(`${this._baseUri}campaign?${queryString}`, {
            method: 'GET',
            mode: 'cors',
            headers: headers
        })
            .then(async response => {
                if (!response.ok) {
                    const message = await response.text()
                    if (response.status === 404) {
                        throw new CampaignNotFoundError(message)
                    } else {
                        throw new Error(message)
                    }
                }

                const token = response.headers.get('token')
                let json = await response.json()
                const campaign = json.data

                return Promise.resolve({ token, campaign })
            })
            .catch(err => {
                console.error(err)
                throw err
            })
    }

    getLead (token: string, code?: string): Promise<Lead> {
        const queryString = QueryString.stringify(cleanupObject({
            code: code
        }))

        return fetch(`${this._baseUri}lead?${queryString}`, {
            method: 'GET',
            mode: 'cors',
            headers: cleanupObject({
                Token: token
            })
        })
            .then(async response => {
                if (!response.ok) {
                    throw new Error(await response.text())
                }

                return response.json()
            })
            .then(json => json['data'])
    }

    updateLead (url: string, code: ?string, data: Lead): Lead {
        throw new Error('NOT IMPLEMENTED')
    }

    patchLead (token: string, code: ?string, fields: Map<string, string>, isComplete: boolean = false): Lead {
        const queryString = QueryString.stringify(cleanupObject({
            code: code
        }))

        return fetch(`${this._baseUri}lead?${queryString}`, {
            method: 'PUT',
            mode: 'cors',
            headers: cleanupObject({
                Token: token
            }),
            body: JSON.stringify({
                data: { fields, isComplete }
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.body)
                }

                return response.json()
            })
            .then(json => json['data'])
    }

    async sendAutoresponder (token: string, attachments: { [string]: Blob }): void {
        const formData = new FormData()

        for (let i in attachments) {
            formData.append(`attachments[${i}]`, attachments[i])
        }

        const response = await fetch(`${this._baseUri}lead/autoresponder?`, {
            method: 'POST',
            mode: 'cors',
            headers: cleanupObject({
                Token: token
            }),
            body: formData
        })

        if (!response.ok) {
            throw new Error(response.body)
        }
    }

    async fetchMakesAndModels (token: string): Promise<Array> {
        const response = await fetch(`${this._baseUri}makes`, {
            method: 'POST',
            mode: 'cors',
            headers: cleanupObject({
                Token: token
            }),
            body: null
        })

        if (!response.ok) {
            throw new Error(response.body)
        }
        return response.json().then(json => json['data'])
    }
}
