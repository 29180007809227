
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nscb3c25f8c01fead123912966697264dden from './en.yaml'
            i18n.addResourceBundle('en', 'cb3c25f8c01fead123912966697264dd', flattenObject(nscb3c25f8c01fead123912966697264dden), true, true);
        
            import nscb3c25f8c01fead123912966697264ddfr from './fr.yaml'
            i18n.addResourceBundle('fr', 'cb3c25f8c01fead123912966697264dd', flattenObject(nscb3c25f8c01fead123912966697264ddfr), true, true);
        
        export default 'cb3c25f8c01fead123912966697264dd';
    