// @flow
import * as React from 'react'

import { Redirect, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { patchLead, sendAutoresponder } from 'Actions'
import { Store } from 'Store'
import CampaignNotFound from 'components/CampaignNotFound'
import Form from './form'

import transDomain from './translations/index.translations'

import styles from './styles.module.scss'
import queryString from 'query-string'

type Props = {
    location: Location
}

export default withRouter((props: Props) => {
    const { state, dispatch } = React.useContext(Store)
    let defaultSessionId = Object.hasOwn(queryString.parse(window.location.hash), 'sId') ? state.campaign.sessionId : ''
    const [sessionId, setSessionId] = React.useState(null)
    const [submitted, setSubmitted] = React.useState(false)
    const { t } = useTranslation(transDomain)

    const onSessionSet = React.useCallback(e => {
        e.preventDefault()

        let sessionId = e.target.elements['session_id'].value
        if (sessionId.toLowerCase() !== state.campaign.sessionId.toLowerCase()) {
            alert('Invalid sessionId provided.')
            return
        }

        setSessionId(sessionId)
    }, [state.campaign])

    const handleFormSubmit = React.useCallback(async (data, done) => {
        try {
            await patchLead(dispatch, state.token, state.code, data, true)
            await sendAutoresponder(dispatch, state.token)
            setSubmitted(true)
            window.scrollTo(0, 0)
            done()
        } catch (err) {
            alert('Something went wrong')
            console.error(err)
        }
    }, [dispatch, state.code, state.token])

    if (state.campaign === null || state.lead === null) {
        return <Redirect to={`/${props.location.search}`} push/>
    }

    React.useEffect(() => {
        document.title = `${state.campaign.client.name} - Desk of the dealer`
    }, [state.campaign.client.name])

    if (state.errors.campaignNotFound) {
        return <CampaignNotFound/>
    }

    return (
        <div>
            <div className={styles.header}>
                <div className={styles.inner}>
                    <div className={styles.logo}>
                        <img src={`/images/logos/${state.campaign.style}.png`} alt="Logo"/>
                    </div>
                    <div>
                        <div className={styles.welcome}>
                            {t('welcome')} {state.username}
                        </div>
                        <h1>{t('title')}</h1>
                    </div>
                </div>
            </div>

            <div className={styles.content}>
                <form onSubmit={onSessionSet} id="session-form">
                    <p>
                        {t('sessionID')} &nbsp;
                        <input type="text" size="8" name="session_id" title="" defaultValue={defaultSessionId}/>&nbsp;
                        <input type="submit" value={t('label.set_session')}/>
                    </p>
                </form>

                <p>{t('description')}</p>

                {submitted &&
                <div className={styles.successMessage}>{t('success')}</div>
                }

                {sessionId ? (
                    <Form
                        key='session-form'
                        onSubmit={handleFormSubmit}
                        initialValues={state.lead.fields}
                    />
                ) : (
                    <Form
                        key='non-session-form'
                        onSubmit={handleFormSubmit}
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: null,
                            dayPhone: null,
                            eveningPhone: null,
                            currentVehicleYear: null,
                            currentVehicleMake: null,
                            currentVehicleModel: null,
                            currentVehicleColor: null,
                            currentVehicleMileage: null
                        }}
                    />
                )}

            </div>

            <div className={styles.footer}>
                <img
                    src={`/images/logos/${state.campaign.style}.png`}
                    alt="Footer Logo"
                    className={styles.footerLogo}
                />

                <div className={styles.sessionInfo}>
                    {t('sessionInfo', {
                        name: state.campaign.client.name,
                        username: state.username,
                        date: new Date().toLocaleString(),
                        sessionId
                    })}
                </div>
            </div>
        </div>
    )
})
