// @flow
import queryString from 'query-string'
import merge from 'deepmerge'
import URL from 'url-parse'

import Client from 'api/Client'
import MockClient from 'api/MockClient'
import FetchClient from 'api/FetchClient'

import mockDataOverrides from 'mock-client-overrides.json'

const query = queryString.parse(window.location.search)

function makeClient (baseUri: ?string): Client {
    if (typeof query['demo'] !== 'undefined') {
        let options = mockDataOverrides

        if (query['demo']) {
            try {
                const demo = JSON.parse(query['demo'])
                options = merge(options, demo)
            } catch (error) {
                throw new Error('Unable to parse JSON')
            }
        }
        return new MockClient('automotive', options)
    }

    return new FetchClient(baseUri)
}

const APIClient = makeClient(process.env.REACT_APP_SHIFT_API_BASE_URL)

let entrypoint = window.sessionStorage.getItem('entrypoint')
if (!entrypoint || (new URL(entrypoint)).pathname.split('/')[1] !== window.location.pathname.split('/')[1]) {
    entrypoint = query._impersonate
        ? `${window.location.protocol}//${query._impersonate}`
        : window.location.href

    window.sessionStorage.setItem('entrypoint', entrypoint)
}

const campaignUrl = new URL(entrypoint)

export const fetchCampaignAndLead = async (dispatch: any => void, username: string, password: string) => {
    const campaignUrl = new URL(window.location.toString())
    const { campaign, token } = await APIClient.getCampaign(campaignUrl, { username, password })

    dispatch({
        type: 'SET_CAMPAIGN',
        payload: campaign
    })

    dispatch({
        type: 'SET_TOKEN',
        payload: token
    })

    dispatch({
        type: 'SET_LOCALE',
        payload: campaign.locales.default
    })

    const lead = await APIClient.getLead(token, password)

    dispatch({
        type: 'SET_LEAD',
        payload: lead
    })

    dispatch({
        type: 'SET_USERNAME',
        payload: username
    })

    dispatch({
        type: 'SET_CODE',
        payload: password
    })

    const makes = await APIClient.fetchMakesAndModels(token)

    dispatch({
        type: 'FETCH_MAKES_MODELS',
        payload: makes
    })
}

export async function patchLead (dispatch: any => void, token: string, code: ?string, fields: any, isComplete: boolean) {
    if (campaignUrl == null) throw new Error('Cannot patch a lead without calling fetchCampaign first.')

    const lead = await APIClient.patchLead(token, code, fields, isComplete)

    dispatch({
        type: 'SET_LEAD',
        payload: lead
    })

    if (isComplete) {
        dispatch({ type: 'MARK_AUTORESPONDER_AS_PENDING' })
    }
}

export function deleteLead (dispatch: any => void) {
    dispatch({
        type: 'SET_LEAD',
        payload: null
    })
}

export async function sendAutoresponder (dispatch: any => void, token: string) {
    dispatch({
        type: 'MARK_AUTORESPONDER_AS_SENT',
        payload: null
    })

    APIClient.sendAutoresponder(token)
}
