// @flow
import React from 'react'
import cn from 'classnames'

import i18n from '../../i18n';

import styles from './styles.module.scss'

function LanguageSwitcher () {
    const [isActive, setIsActive] = React.useState('en')
    const setLang = (lng) => {
        i18n.changeLanguage(lng).then(() => setIsActive(lng));
    }

    return (
        <div className={styles.container}>
            <div onSubmit={setLang} className={styles.langPicker}>
                <div className={styles.inner}>
                    <button onClick={() => setLang('en')} className={cn(styles.button, { [styles.isActive]: 'en' === isActive })}>
                        English
                    </button>
                    <button onClick={() => setLang('fr')} className={cn(styles.button, { [styles.isActive]: 'fr' === isActive })}>
                        Français
                    </button>
                </div>
            </div>
        </div>
    )
}

export default LanguageSwitcher
