// @flow
export function cleanupObject (obj: Object): Object {
    let newObj = {}
    Object.keys(obj).forEach((prop) => {
        if (typeof obj[prop] !== 'undefined' || obj[prop] !== null) {
            newObj[prop] = obj[prop]
        }
    })
    return newObj
}

export function sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}
