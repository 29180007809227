export default {
    'Acura':
        [
            'ILX',
            'MDX',
            'RDX',
            'RLX',
            'TLX',
        ],
    'Audi':
        [
            'A3',
            'A4',
            'A4 Allroad',
            'A5',
            'A6',
            'A7',
            'A8',
            'Q3',
            'Q5',
            'Q7',
            'R8',
            'S4',
            'S5',
            'S6',
            'S7',
            'S8',
            'SQ5',
            'TT',
        ],
    'BMW':
        [
            '2 Series',
            '2 Series Cabriolet',
            '3 Series Gran Turismo',
            '3 Series Sedan',
            '3 Series Touring',
            '4 Series Cabriolet',
            '4 Series CoupÃ©',
            '4 Series Gran CoupÃ©',
            '5 Series Sedan',
            '6 Series Cabriolet',
            '6 Series Gran Coupe',
            '7 Series Sedan',
            'ActiveHybrid 3',
            'ActiveHybrid 5',
            'ActiveHybrid 7',
            'i3',
            'i8',
            'M2 CoupÃ©',
            'M3 Coupe',
            'M4 Cabriolet',
            'M5 Sedan',
            'M6 Cabriolet',
            'M6 Coupe',
            'M6 Gran Coupe',
            'X1',
            'X3',
            'X4',
            'X5',
            'X6',
            'Z4 Roadster',
        ],
    'Buick':
        [
            'Cascada',
            'Enclave',
            'Encore',
            'Lacrosse',
            'Regal',
            'Verano',
        ],
    'Cadillac':
        [
            'ATS Coupe',
            'ATS Sedan',
            'CT6 Sedan',
            'CTS Coupe',
            'CTS Sedan',
            'CTS Sport Wagon',
            'ELR Coupe',
            'Escalade',
            'Escalade ESV',
            'Escalade Hybrid',
            'SRX',
            'XTS',
        ],
    'Chevrolet':
        [
            'Camaro',
            'City Express',
            'Colorado',
            'Corvette',
            'Corvette Convertible',
            'Corvette Z06',
            'Corvette ZR1',
            'Cruze',
            'Equinox',
            'Express',
            'Impala',
            'Malibu',
            'Silverado 1500',
            'Silverado 2500HD',
            'Silverado 3500HD',
            'Sonic',
            'Spark',
            'Spark EV',
            'SS',
            'Suburban',
            'Tahoe',
            'Traverse',
            'Trax',
            'Volt',
        ],
    'Chrysler':
        [
            '200',
            '300',
            '300C',
            'Town and Country',
        ],
    'Dodge':
        [
            'Challenger',
            'Charger',
            'Dart',
            'Durango',
            'Grand Caravan',
            'Grand Caravan SXT, RT or Crew',
            'Journey',
            'Journey SXT, RT or Crew',
        ],
    'Fiat':
        [
            '500',
            '500 ABARTH',
            '500 TURBO',
            '500c',
            '500L',
            '500X',
        ],
    'Ford':
        [
            'C-MAX Energi',
            'C-MAX Hybrid',
            'E-Series',
            'Edge',
            'Escape',
            'Expedition',
            'Explorer',
            'F-150',
            'F-150 STX',
            'F-150 XL',
            'Fiesta',
            'Flex',
            'Focus',
            'Focus Electric',
            'Fusion',
            'Fusion Energi',
            'Fusion Hybrid',
            'Mustang',
            'Shelby GT 500',
            'Super Duty F-250',
            'Super Duty F-350',
            'Super Duty F-450',
            'Taurus',
            'Transit Connect',
        ],
    'GMC':
        [
            'Acadia',
            'Canyon',
            'Savana',
            'Sierra',
            'Sierra - Reg. Cab',
            'Sierra HD',
            'Terrain',
            'Yukon',
        ],
    'Honda':
        [
            'Accord Coupe',
            'Accord Hybrid',
            'Accord Sedan',
            'Civic Coupe',
            'Civic Hybrid',
            'Civic Sedan',
            'Civic Si Coupe',
            'Civic Si Sedan',
            'CR-V',
            'CR-Z',
            'Crosstour',
            'Fit',
            'HR-V',
            'Odyssey',
            'Pilot',
            'Ridgeline',
        ],
    'Hyundai':
        [
            'Accent Hatchback',
            'Accent Sedan',
            'Azera',
            'Elantra Coupe',
            'Elantra GT',
            'Elantra Sedan',
            'Equus',
            'Genesis Coupe',
            'Genesis Sedan',
            'Santa Fe Sport',
            'Santa Fe XL',
            'Sonata',
            'Sonata Hybrid',
            'Tucson',
            'Tucson FCEV',
            'Veloster',
            'Veloster Turbo',
        ],
    'Infiniti':
        [
            'Q50',
            'Q50 Hybrid',
            'Q60',
            'Q60 Convertible',
            'Q60 IPL Convertible',
            'Q70',
            'Q70 Hybrid',
            'QX50',
            'QX60',
            'QX60 Hybrid',
            'QX70',
            'QX80',
        ],
    'Jaguar':
        [
            'F-Pace',
            'F-Type',
            'XE',
            'XF',
            'XJ',
        ],
    'Jeep':
        [
            'Cherokee',
            'Compass',
            'Grand Cherokee',
            'Patriot',
            'Renegade',
            'Wrangler',
        ],
    'Kia':
        [
            'Cadenza',
            'Forte',
            'Forte Koup',
            'Forte5',
            'K900',
            'Optima',
            'Optima Hybrid',
            'Rio',
            'Rio5',
            'Rondo',
            'Sedona',
            'Sorento',
            'Soul',
            'Soul EV',
            'Sportage',
        ],
    'Land Rover':
        [
            'Discovery Sport',
            'LR4',
            'Ranger Rover',
            'Ranger Rover Evoque',
            'Ranger Rover Sport',
        ],
    'Lexus':
        [
            'CT 200h F Sport',
            'ES 300h',
            'ES 350',
            'GS 350',
            'GS 450h',
            'GX 460',
            'IS 250',
            'IS 250 AWD',
            'IS 300 AWD',
            'IS 350 AWD',
            'LFA',
            'LS 460',
            'LS 460 AWD F Sport',
            'LS 600h L',
            'LX 570',
            'NX',
            'NX 200t',
            'NX Hybrid',
            'RC',
            'RC 350 AWD',
            'RX 350',
            'RX 450h',
        ],
    'Lincoln':
        [
            'MKC',
            'MKS',
            'MKT',
            'MKX',
            'MKZ',
            'MKZ Hybrid',
            'Navigator',
        ],
    'Mazda':
        [
            '2',
            '3',
            '3 Sport',
            '5',
            '6',
            'CX-3',
            'CX-5',
            'CX-9',
            'MX-5',
        ],
    'Mercedes':
        [
            'B-Class',
            'C-Class Coupe',
            'C-Class Sedan',
            'CLA-Class',
            'CLS-Class',
            'E-Class',
            'G-Class',
            'GL-Class',
            'GLA-Class',
            'GLE-Class',
            'GLK-Class',
            'S-Class',
            'SL-Class',
            'SLK-Class',
            'SLS AMG',
            'Sprinter Van',
        ],
    'MINI':
        [
            '3 Door',
            '5 Door',
            'Clubman',
            'Clubvan',
            'Convertible',
            'Cooper Classic',
            'Countryman',
            'CoupÃ©',
            'Paceman',
            'Roadster',
        ],
    'Mitsubishi':
        [
            'i-MiEV',
            'Lancer',
            'Lancer Evolution',
            'Lancer Ralliart',
            'Lancer Sportback',
            'Mirage',
            'Mirage G4',
            'Outlander',
            'Outlander Sport',
            'RVR',
        ],
    'Nissan':
        [
            '370Z Coupe',
            '370Z Roadster',
            'Altima',
            'Armada',
            'Frontier Crew Cab',
            'Frontier King Cab',
            'GT-R',
            'Juke',
            'Leaf',
            'Maxima',
            'Micra',
            'Murano',
            'Pathfinder',
            'Rogue',
            'Sentra',
            'Titan Crew Cab',
            'Titan King Cab',
            'Titan XD',
            'Versa Note',
            'Versa Sedan',
            'Xterra',
        ],
    'Porsche':
        [
            '911 Carrera',
            '911 Carrera 4',
            '911 Carrera 4 Cabriolet',
            '911 Carrera 4S',
            '911 Carrera 4S Cabriolet',
            '911 Carrera Cabriolet',
            '911 Carrera S',
            '911 Carrera S Cabriolet',
            '911 GT3',
            '911 Targa 4',
            '911 Targa 4S',
            '911 Turbo',
            '911 Turbo Cabriolet',
            '911 Turbo S',
            '911 Turbo S Cabriolet',
            '918 Spyder',
            '918 Spyder Weissach Package',
            'Boxster',
            'Boxster GTS',
            'Boxster S',
            'Cayenne',
            'Cayenne Diesel',
            'Cayenne Diesel Platinum Edition',
            'Cayenne GTS',
            'Cayenne Platinum Edition',
            'Cayenne S',
            'Cayenne S Hybrid',
            'Cayenne Turbo',
            'Cayenne Turbo S',
            'Cayman',
            'Cayman GTS',
            'Cayman S',
            'Macan S',
            'Macan Turbo',
            'Panamera',
            'Panamera 4',
            'Panamera 4S',
            'Panamera 4S Executive',
            'Panamera GTS',
            'Panamera S',
            'Panamera S E-Hybrid',
            'Panamera Turbo',
            'Panamera Turbo Executive',
            'Panamera Turbo S',
            'Panamera Turbo S Executive',
        ],
    'Ram Truck':
        [
            '1500',
            'Chassis Cab',
            'HD 2500',
            'HD 3500',
            'PROMASTER',
            'PROMASTER CITY',
        ],
    'Saturn':
        [
            'Astra',
            'Aura',
            'Aura Hybrid',
            'Outlook',
            'Sky',
            'Vue',
            'Vue Hybrid',
        ],
    'Scion':
        [
            'FR-S',
            'iA',
            'iM',
            'tC',
        ],
    'smart':
        [
            'fortwo cabriolet',
            'fortwo coupÃ©',
            'fortwo ED cabriolet',
            'fortwo ED coupe',
        ],
    'Subaru':
        [
            'BRZ',
            'Forester',
            'Impreza',
            'Legacy',
            'Outback',
            'WRX',
            'WRX STI',
        ],
    'Toyota':
        [
            '4Runner',
            'Avalon',
            'Avalon Hybrid',
            'Camry',
            'Camry Hybrid',
            'Corolla',
            'Highlander',
            'Highlander Hybrid',
            'Prius',
            'Prius c',
            'Prius Plug-in',
            'Prius v',
            'RAV4',
            'Rav4 Hybrid',
            'Sequoia',
            'Sienna',
            'Tacoma',
            'Tundra',
            'Venza',
            'Yaris Hatchback',
            'Yaris Sedan',
        ],
    'Volkswagen':
        [
            'Beetle',
            'Beetle Convertible',
            'Beetle GSR',
            'CC',
            'Eos',
            'Golf',
            'Golf GTI',
            'Golf Sportwagon',
            'Jetta',
            'Jetta Comfortline',
            'Jetta GLI',
            'Jetta Highline',
            'Jetta T-Hybrid',
            'Jetta TDI',
            'Jetta Trendline',
            'Passat',
            'Passat TDI',
            'Tiguan',
            'Touareg',
        ],
    'Volvo':
        [
            'S60',
            'S80',
            'V60',
            'V60 Cross Country',
            'XC60',
            'XC70',
            'XC90',
        ]
}
